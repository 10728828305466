<template>
  <div class="invalid-service">
    <div style="text-align: center;">
      <p style="magrin-top: 10px;">نعتذر منك فالخدمة متوقفة في صفحة</p>
      <p style="magrin-top: 10px;">
        <b> {{ $store.getters["page/name"] }} </b>
      </p>
      <p style="magrin-top: 10px;">يرجى التواصل معنا</p>
      <p style="magrin-top: 10px;">
        <a style="color: white; margin-top: 20px; line-height: 30px;" href="tel:+963957233971">{{
          phone1
        }}</a>
      </p>
      <p style="magrin-top: 10px;">
        <a style="color: white; margin-top: 20px; line-height: 30px;" href="tel:+963957233973">{{
          phone2
        }}</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phone1: +963957233971,
      phone2: +963957233973
    };
  }
};
</script>

<style lang="scss" scoped>
.invalid-service {
  background: dodgerblue;
  width: 100%;
  height: calc(100vh - 50px);
  color: white;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
</style>
